import React from "react";
import MyGrid from "./components/MyGrid/MyGrid";
import { useTranslation } from "react-i18next";
import Header from "./components/Header/Header";
import { ClipLoader } from "react-spinners";

function App() {
  const { t } = useTranslation();
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [contentModel, setContentModel] = React.useState(false);
  // const contentModel = window.ContentModel;
  // let contentModel = null;

  React.useEffect(() => {
    async function fetchData() {
      try {
      const res = await fetch("/getdata?id=" + window.token);
      if (res.status !== 200) {
        // console.error("Error:", res);
        setIsLoaded(true);
        setError(res);
      }
      res
        .json()
        .then((res) => {
          setContentModel(res);
          setIsLoaded(true);
        })
        .catch((error) => {
          // console.error(error);
          setIsLoaded(true);
          setError(error);
        });
      } catch (error) {
        // console.error("Error:", error);
        setIsLoaded(true);
        setError(error);
      }
    }
    fetchData();
  }, []);
  if (error) {
    // history.replace("/help");
    // window.location.reload();
    console.log("Generic error");
    return (
      <div className="errorPageBackground">
        <Header />
        <div className="paddingLeft64 maxWidth800 paddingBottom120">
          <div className="semibold-h200 cl-secondary-400 paddingBottom24">
            Error {error && error.status ? error.status : null}
          </div>
          <div className="semibold-h400 cl-secondary-100 paddingBottom32">
           {error && error.status === 581 ? t("ErrorPage_notvalid_description") : (error && error.status > 581 ? t("ValidationMessage_" + error.status) : t("ErrorPage_unknown_description")) }
          </div>
        </div>
      </div>
    );
  } else if (!isLoaded) {
    console.log("Page Not Loaded");
    return (
      <div>
        <Header />
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <ClipLoader size={200} color={"#504a4a"} />
        </div>
      </div>
    );
  } else {
    console.log("Page Loaded");
    return (
      <>
        <Header />
        <div className="Iese_Content_Wrapper">
          {/* <h1 className="semibold-h500 cl-primary-500">{t("TitleLiteral")}</h1> */}
          {contentModel.Error ? (
            <label className="redTitle">Error</label>
          ) : (
            <MyGrid contentModel={contentModel} />
          )}
        </div>
      </>
    );
  }
}
export default App;
