import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import PayerModal from "../PayerModal";
import MessageModal from "../MessageModal";
import { Tooltip } from "@mui/material";
import countriesdata from "../../../assets/data/countriesdata.json";

export default function PayerGrid(props) {
  const { t } = useTranslation();
  const [payerGridOpen, setPayerGridOpen] = useState(false);
  const [currentPayerEditElement, setCurrentPayerEditElement] = useState(0);
  const [messageModalOpen, setMessageModalOpen] = React.useState(false);
  const [messageModalLiteral, setMessageModalLiteral] = React.useState("");
  const [messageModalCloseButtonLiteral, setMessageModalCloseButtonLiteral] =
    React.useState("");
  const [
    messageModalConfirmButtonLiteral,
    setMessageModalConfirmButtonLiteral,
  ] = React.useState("");
  const [messageModalConfirm, setMessageModalConfirm] = React.useState(false);
  const [currentPayerDeleteElement, setCurrentPayerDeleteElement] = useState(0);
  const openCurrentPayerGridElement = (element) => {
    element.previousPayerFiscalID = element.payerId;
    setCurrentPayerEditElement(element);
    setPayerGridOpen(true);
  };
  const openNewPayerElement = () => {
    setCurrentPayerEditElement({
      payerId: "",
      facturationAddress: "",
      payer: "",
      payerFiscalType: "F004",
      payerType: "Personal",
      payerFacturationCountry: "",
      payerFullname: "",
      payerFacturationMail: "",
      newPayerIntent: true,
      editable: true,
      deleted: false,
      canDelete: true
    });
    setPayerGridOpen(true);
  };
  const deleteCurrentPayerGridElement = (deleteelement) => {
    let payerPayments = props.contentModel.paymentsList.filter(
      (element) =>
        element.payerPayments.filter(
          (element) =>
            element.deleted == false && element.payerId == deleteelement.payerId
        ).length > 0
    );
    if (payerPayments.length > 0) {
      setMessageModalConfirm(false);
      setMessageModalLiteral(t("PayerHasPaymentsMessage"));
      setMessageModalCloseButtonLiteral(t("CloseButton"));
      setMessageModalConfirmButtonLiteral(null);
      setMessageModalOpen(true);
    } else {
      setCurrentPayerDeleteElement(deleteelement);
      setMessageModalConfirm(true);
      setMessageModalLiteral(t("ConfirmPayerDeleteMessage"));
      setMessageModalCloseButtonLiteral(t("CancelButton"));
      setMessageModalConfirmButtonLiteral(t("DeleteButton"));
      setMessageModalOpen(true);
    }
  };
  const confirmDeleteCurrentPayerGridElement = () => {
    // let payersUpdated = props.contentModel.payers.filter(
    //   (element) => element.payerId != currentPayerDeleteElement.payerId
    // );

    // props.contentModel.payers = payersUpdated;
    currentPayerDeleteElement.deleted = true;
    props.contentModel.payers = props.contentModel.payers.map((element) => {
      if (element.payerId === currentPayerDeleteElement.payerId) {
        return { ...currentPayerDeleteElement };
      } else {
        // No changes
        return element;
      }
    });

    props.setContentModel({ ...props.contentModel });
    closeMessageConfirmModal();
  };
  const closeMessageConfirmModal = () => {
    setCurrentPayerDeleteElement(0);
    setMessageModalConfirm(false);
    setMessageModalOpen(false);
  };
  let count = 1;
  return (
    <>
      <h3 className="semibold-h300">
        {t("PaymentsTable_Payers_Literal")}
        <div style={{ paddingTop: "12px" }}>
          <Button
            style={{ padding: "0" }}
            className="IESE_Close_Button cl-primary-500 IESE_Confirm_Button"
            variant="text"
            onClick={() => {
              openNewPayerElement();
            }}
          >
            {t("AddButton")}
          </Button>
          <span className="regular-b300" style={{ paddingLeft: "48px", fontWeight: "400" }}>{t("PaymentsTable_PayersInfo_Literal")}</span>
        </div>
      </h3>
      <div style={{ overflow: "auto" }}>
        <Table className="iese-payments-table">
          <TableHead>
            <TableRow style={{ backgroundColor: "red" }}  key={count++}>
              <TableCell></TableCell>
              <TableCell style={{ color: "white" }}>{t("PayerTable_PayerName_Literal")}</TableCell>
              <TableCell style={{ color: "white" }}>{t("PayerTable_PayerAddressCity_Literal")}</TableCell>
              <TableCell style={{ color: "white" }}>{t("PayerTable_PayerAddressCountry_Literal")}</TableCell>
              <TableCell style={{ color: "white" }}>{t("PayerTable_PayerAddressCP_Literal")}</TableCell>
              <TableCell style={{ color: "white" }}>{t("PayerTable_PayerID_Literal")}</TableCell>
              <TableCell style={{ color: "white" }}><Tooltip title={t("PayerTable_PayerEmailTooltip_Literal")}><span style={{ color: "white" }}>{t("PayerTable_PayerAddressMail_Literal")}</span></Tooltip></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.contentModel.payers.map(
              (item) =>
                item.deleted != true && (
                  <TableRow key={count++}>
                    <TableCell>
                    {item.editable && <Button
                        style={{ padding: "0" }}
                        className="IESE_Close_Button cl-primary-500"
                        variant="text"
                        onClick={() => {
                          openCurrentPayerGridElement(item);
                        }}
                      >
                        {t("EditButton")}
                      </Button>}
                      {item.canDelete && <Button
                        style={{ padding: "0" }}
                        className="IESE_Close_Button cl-primary-500"
                        variant="text"
                        onClick={() => {
                          deleteCurrentPayerGridElement(item);
                        }}
                      >
                        {t("DeleteButton")}
                      </Button>}
                    </TableCell>
                    <TableCell>{item.payerFullname}</TableCell>
                    <TableCell>{item.payerFacturationCity}</TableCell>
                    <TableCell>{countriesdata.countries.find((c) => c.value == item.payerFacturationCountry)?.text ?? ''}</TableCell>
                    <TableCell>{item.payerFacturationCP}</TableCell>
                    <TableCell>{item.payerId}</TableCell>
                    <TableCell>{item.payerFacturationMail}</TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
        <PayerModal
          contentModel={props.contentModel}
          setContentModel={props.setContentModel}
          payerGridOpen={payerGridOpen}
          setPayerGridOpen={setPayerGridOpen}
          currentPayerEditElement={currentPayerEditElement}
          setCurrentPayerEditElement={setCurrentPayerEditElement}
        />
        <MessageModal
          show={messageModalOpen}
          close={closeMessageConfirmModal}
          messageModalLiteral={messageModalLiteral}
          messageModalCloseButtonLiteral={messageModalCloseButtonLiteral}
          messageModalConfirmButtonLiteral={messageModalConfirmButtonLiteral}
          confirm={messageModalConfirm}
          confirmfunc={confirmDeleteCurrentPayerGridElement}
        />
      </div>
    </>
  );
}
PayerGrid.propTypes = {
  contentModel: PropTypes.object.isRequired,
  setContentModel: PropTypes.func.isRequired,
};
