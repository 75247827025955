import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Header() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className="Iese_Header_Wrapper">
      <div className="Iese_Header_IESELogo">
        <a href="/">
          <img
            loading="lazy"
            alt="IeseLogo"
            className="ieselogo"
            height="32"
            src="/img/headerieseicon.svg"
          />
        </a>
      </div>
      <div>
        <Button
          style={{ padding: "0" }}
          className="IESE_Close_Button cl-primary-500"
          variant="text"
          onClick={() => {
            changeLanguage("en");
          }}
        >
          {t("Header_English")}
        </Button>
        <Button
          style={{ padding: "0" }}
          className="IESE_Close_Button cl-primary-500"
          variant="text"
          onClick={() => {
            changeLanguage("es");
          }}
        >
          {t("Header_Spanish")}
        </Button>
      </div>
    </div>
  );
}
