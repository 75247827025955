import React from "react";
import PropTypes from "prop-types";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useTranslation } from "react-i18next";

export default function ProgramGrid(props) {
  const { t } = useTranslation();
  const totalProgramCost =
    props.contentModel.programs.length > 1
      ? props.contentModel.programs.reduce(function (a, b) {
          return a.amount + b.amount;
        })
      : props.contentModel.programs[0].amount;
  const totalDiscountCost =
    props.contentModel.discounts.length > 1
      ? props.contentModel.discounts.reduce(function (a, b) {
          return a.amount + b.amount;
        })
      : props.contentModel.discounts[0]
      ? props.contentModel.discounts[0].amount
      : 0;
  const totalCost = totalProgramCost - totalDiscountCost;
  let count = 1;
  // Create our number formatter.
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: props.contentModel.currency,

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return (
    <>
      <h3 className="semibold-h300">{t("ProgramFee_Literal")}</h3>
      <div style={{ overflow: "auto" }}>
        <Table className="iese-payments-table" style={{ width: "auto" }}>
          <TableHead>
            <TableRow style={{ backgroundColor: "red" }} key={count++}>
              <TableCell>
                {/* {t("PaymentsTable_Concept_Literal")} */}
              </TableCell>
              <TableCell style={{ textAlign: "right", color: "white" }}>
                {t("Price_Literal")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.contentModel.programs.map((item) => (
              <TableRow key={count++}>
                <TableCell>{item.description}</TableCell>
                <TableCell style={{ textAlign: "right", paddingLeft: "48px" }}>
                  {item.amount && formatter.format(item.amount)}
                </TableCell>
              </TableRow>
            ))}
            {props.contentModel.discounts.map((item) => (
              <TableRow key={count++}>
                <TableCell>{item.concept}</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  -{item.amount && formatter.format(item.amount)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow style={{ backgroundColor: "red" }} key={count++}>
              <TableCell className="semibold-b200" style={{ color: "white" }}>{t("ProgramFee_Literal")}</TableCell>
              <TableCell className="semibold-b200" style={{ textAlign: "right", color: "white" }}>
                {formatter.format(totalCost)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
}
ProgramGrid.propTypes = {
  contentModel: PropTypes.object.isRequired,
  setContentModel: PropTypes.func.isRequired,
};
