import React, { useState } from "react";
import { Button, Grid } from "@mui/material/index";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import PaymentPlanGrid from "./Grid/PaymentPlanGrid";
import ProgramGrid from "./Grid/ProgramGrid";
// import DiscountsGrid from "./Grid/DiscountsGrid";
// import TotalGrid from "./Grid/TotalGrid";
import PayerGrid from "./Grid/PayerGrid";
// import PaymentGrid from "./Grid/PaymentGrid";
import MessageModal from "./MessageModal";
import { ClipLoader } from "react-spinners";

export default function MyGrid(props) {
  const { t } = useTranslation();
  const [contentModel, setContentModel] = useState(props.contentModel);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [messageModalOpen, setMessageModalOpen] = React.useState(false);
  const [messageModalLiteral, setMessageModalLiteral] = React.useState("");
  const [messageModalCloseButtonLiteral, setMessageModalCloseButtonLiteral] =
    React.useState("");
  const updateContent = (content) => {
    content.modified = true;
    setContentModel({ ...content });
  };
  async function postData(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  const saveContentData = () => {
    if (isConfirmed) {
      setMessageModalLiteral(t("ValidationMessage_586"));
      setMessageModalCloseButtonLiteral(t("CloseButton"));
      setMessageModalOpen(true);
    } else {
      setIsLoaded(false);
      postData("/Home/SaveData", contentModel).then((data) => {
        console.log(data); // JSON data parsed by `data.json()` call
        if (data.code && (data.code >= 582 || data.code == 403)) {
          setMessageModalLiteral(t("ValidationMessage_" + data.code));
          setMessageModalCloseButtonLiteral(t("CloseButton"));
          setMessageModalOpen(true);
        } else {
          setMessageModalLiteral(t("ValidationMessage_200"));
          setMessageModalCloseButtonLiteral(t("CloseButton"));
          setMessageModalOpen(true);
          setIsConfirmed(true);
        }
        setIsLoaded(true);
      });
    }
  };
  const closeMessageConfirmModal = () => {
    setMessageModalOpen(false);
  };

  return (
    <div>
      {!isLoaded && <div style={{textAlign: "center", position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",}}><ClipLoader size={200} color={"#504a4a"} /></div>}
      {isLoaded && <>
      <div className="participantInfoWrapper">
        <table>
          <tbody>
            <tr>
              <td>
                <span className="semibold-b300">
                  {t("Participant_Literal")}
                </span>
              </td>
              <td>
                <span className="regular-b300">{contentModel.username}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="semibold-b300">{t("Program_Literal")}</span>
              </td>
              <td>
                <span className="regular-b300">{contentModel.programName}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ paddingBottom: "8px", whiteSpace: "pre-line", lineHeight: "1.3" }}>
        {t("Header_Welcome_Message", {
          name: contentModel.username,
          program: contentModel.programName,
        })}
      </div>
      <div>{t("Header_Welcome_Message2")}</div>
      <ProgramGrid
        contentModel={contentModel}
        setContentModel={updateContent}
      />
      {/* <DiscountsGrid
        contentModel={contentModel}
        setContentModel={updateContent}
      />
      <TotalGrid
        contentModel={contentModel}
        setContentModel={updateContent}
      /> */}
      <PayerGrid contentModel={contentModel} setContentModel={updateContent} />
      {/* <PaymentGrid
        contentModel={contentModel}
        setContentModel={updateContent}
      /> */}
      <PaymentPlanGrid
        contentModel={contentModel}
        setContentModel={updateContent}
      />
      <Grid textAlign={"right"} marginTop={"20px"} paddingBottom={"40px"}>
        <Button
          style={{ marginLeft: "16px" }}
          className="IESE_Close_Button semibold-b200 cl-primary-100 IESE_Confirm_Button"
          variant="text"
          onClick={() => saveContentData()}
        >
          {t("SendButton")}
        </Button>
      </Grid>
      </>}
      <MessageModal
        show={messageModalOpen}
        close={closeMessageConfirmModal}
        messageModalLiteral={messageModalLiteral}
        messageModalCloseButtonLiteral={messageModalCloseButtonLiteral}
      />
    </div>
  );
}
MyGrid.propTypes = {
  contentModel: PropTypes.object.isRequired,
  setIsLoaded: PropTypes.func
};
