import React from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/es";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import MessageModal from "./MessageModal";
// import ValidateSpanishID from "../../helpers/ValidateSpanishID";

export default function PaymentModal(props) {
  dayjs.extend(customParseFormat);
  const { t } = useTranslation();
  const [messageModalOpen, setMessageModalOpen] = React.useState(false);
  const [messageModalLiteral, setMessageModalLiteral] = React.useState("");
  const [messageModalCloseButtonLiteral, setMessageModalCloseButtonLiteral] =
    React.useState("");
  // const [currentPaymentSelectedPayer, setCurrentPaymentSelectedPayer] =
  //   React.useState({
  //     newPayerIntent: true,
  //     deleted: false,
  //     payerId: "",
  //     payerType: "Personal",
  //     payerFullname: "",
  //     payerFiscalType: "F001",
  //     payerFacturationAddress: "",
  //     payerFacturationCity: "",
  //     payerFacturationProvince: "",
  //     payerFacturationCP: "",
  //     payerFacturationCountry: "",
  //     payerFacturationMail: "",
  //   });
  // const setPayerFromParameter = (payer) => {
  //   if (payer) {
  //     setCurrentPaymentSelectedPayer(payer);
  //   } else {
  //     setCurrentPaymentSelectedPayer({
  //       newPayerIntent: true,
  //       deleted: false,
  //       payerId: "",
  //       payerType: "Personal",
  //       payerFullname: "",
  //       payerFiscalType: "F001",
  //       payerFacturationAddress: "",
  //       payerFacturationCity: "",
  //       payerFacturationProvince: "",
  //       payerFacturationCP: "",
  //       payerFacturationCountry: "",
  //       payerFacturationMail: "",
  //     });
  //   }
  // };
  // useEffect(() => {
  //   const payer = props.contentModel.payers.find(
  //     (p) =>
  //       props.currentPaymentEditElement.payerId &&
  //       p.payerId == props.currentPaymentEditElement.payerId
  //   );
  //   setPayerFromParameter(payer);
  // }, [props.paymentGridOpen]);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    props.setCurrentPaymentEditElement({
      ...props.currentPaymentEditElement,
      [name]: value,
    });
    // if (name == "payerId") {
    //   const payer = props.contentModel.payers.find((p) => p.payerId == value);
    //   setPayerFromParameter(payer);
    // }
  };
  // const handleInputChangePayer = (event) => {
  //   const target = event.target;
  //   const value = target.value;
  //   const name = target.name;

  //   setCurrentPaymentSelectedPayer({
  //     ...currentPaymentSelectedPayer,
  //     [name]: value,
  //   });
  // };
  const handleDatePickerInputChange = (name, event) => {
    const value = event.format("DD/MM/YYYY");

    props.setCurrentPaymentEditElement({
      ...props.currentPaymentEditElement,
      [name]: value,
    });
  };
  const parseDMY = (s) => {
    let [d, m, y] = s.split(/\D/);
    return new Date(y, m - 1, d);
  };

  // Create our number formatter.
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: props.contentModel.currency,

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  const saveCurrentPaymentGridElement = () => {
    let payerPaymentsUpdated = [];
    if (
      !props.currentPaymentEditElement.amount ||
      !props.currentPaymentEditElement.deadline ||
      !props.currentPaymentEditElement.paymentId ||
      !props.currentPaymentEditElement.payerId
    ) {
      setMessageModalLiteral(t("RequiredFieldsMessage"));
      setMessageModalCloseButtonLiteral(t("CloseButton"));
      setMessageModalOpen(true);
      return false;
    }
    // if (
    //   !props.currentPaymentEditElement.amount ||
    //   !props.currentPaymentEditElement.deadline ||
    //   !currentPaymentSelectedPayer.payerFacturationAddress ||
    //   !currentPaymentSelectedPayer.payerFullname
    // ) {
    //   setMessageModalLiteral(t("RequiredFieldsMessage"));
    //   setMessageModalCloseButtonLiteral(t("CloseButton"));
    //   setMessageModalOpen(true);
    //   return false;
    // }
    // if (!ValidateSpanishID(currentPaymentSelectedPayer.payerId).valid) {
    //   setMessageModalLiteral(t("InvalidFiscalIDMessage"));
    //   setMessageModalCloseButtonLiteral(t("CloseButton"));
    //   setMessageModalOpen(true);
    //   return false;
    // }
    // VALIDAR AMOUNT MAYOR MINIMO POR PAGO
    if (
      Number(props.currentPaymentEditElement.amount) <
      Number(props.contentModel.minPaymentAmount)
    ) {
      setMessageModalLiteral(
        t("ValidationHelper_MinPaymentAmountMessage", {
          num: formatter.format(props.contentModel.minPaymentAmount),
        })
      );
      setMessageModalCloseButtonLiteral(t("CloseButton"));
      setMessageModalOpen(true);
      return false;
    }
    // VALIDAR AMOUNT POSITIVE
    if (Number(props.currentPaymentEditElement.amount) < 0) {
      setMessageModalLiteral(t("PositiveAmountMessage"));
      setMessageModalCloseButtonLiteral(t("CloseButton"));
      setMessageModalOpen(true);
      return false;
    }

    // VALIDAR DEADLINE MAYOR QUE HOY
      if (parseDMY(props.currentPaymentEditElement.deadline) < new Date() && props.currentPaymentEditElement.status != "PAID") {
      setMessageModalLiteral(t("PositiveDeadlineMessage"));
      setMessageModalCloseButtonLiteral(t("CloseButton"));
      setMessageModalOpen(true);
      return false;
    }
    // VALIDAR DEADLINE MENOR QUE DUE DATE
    const currentpaymentduedate = props.contentModel.paymentsList.find(
      (el) => el.paymentId === props.currentPaymentEditElement.paymentId
      ).duedate;
      if (parseDMY(props.currentPaymentEditElement.deadline) > parseDMY(currentpaymentduedate)) {
      setMessageModalLiteral(t("DeadlineGreaterThanDueDateMessage"));
      setMessageModalCloseButtonLiteral(t("CloseButton"));
      setMessageModalOpen(true);
      return false;
    }
    // if (currentPaymentSelectedPayer.newPayerIntent) {
    //   props.currentPaymentEditElement.payerId =
    //     currentPaymentSelectedPayer.payerId;
    // }
    if (props.currentPaymentEditElement.newPaymentIntent) {
      payerPaymentsUpdated = payerPaymentsUpdated.concat(
        props.contentModel.paymentsList.find(
          (element) =>
            element.paymentId == props.currentPaymentEditElement.paymentId
        ).payerPayments
      );
      props.currentPaymentEditElement.created = true;
      props.currentPaymentEditElement.newPaymentIntent = undefined;
      // const nextPayerId =
      //   Math.max(...payerPaymentsUpdated.map((p) => p.id), 0) + 1;
     const nextPayerId =
      payerPaymentsUpdated.length + 1;
      props.currentPaymentEditElement.id = '' + nextPayerId;
      payerPaymentsUpdated.push(props.currentPaymentEditElement);
    } else {
      props.currentPaymentEditElement.modified = true;
      payerPaymentsUpdated = props.contentModel.paymentsList
        .find(
          (element) =>
            element.paymentId == props.currentPaymentEditElement.paymentId
        )
        .payerPayments.map((element) => {
          if (element.id === props.currentPaymentEditElement.id) {
            return { ...props.currentPaymentEditElement };
          } else {
            // No changes
            return element;
          }
        });
    }
    // VALIDAR QUE EL PAGADOR ACTUAL NO ESTA DOS VECES EN EL CONCEPTO CON MISMA FECHA DEADLINE
    if (payerPaymentsUpdated.filter(pp => pp.deleted == false && pp.deadline == props.currentPaymentEditElement.deadline && pp.payerId == props.currentPaymentEditElement.payerId).length > 1) {
      if (props.currentPaymentEditElement.created == true) {
        props.currentPaymentEditElement.newPaymentIntent = true;
      }
      setMessageModalLiteral(t("PayerDuplicatedMessage"));
      setMessageModalCloseButtonLiteral(t("CloseButton"));
      setMessageModalOpen(true);
      return false;
    }
    // VALIDAR MAXPAYMENTSNUMBER
    if (new Set(payerPaymentsUpdated.filter(pp => pp.deleted == false && pp.editable).map(pp => pp.deadline)).size > props.contentModel.maxPayments) {
      if (props.currentPaymentEditElement.created == true) {
        props.currentPaymentEditElement.newPaymentIntent = true;
      }
      setMessageModalLiteral(t("ValidationHelper_MaxPaymentsMessage", { num: props.contentModel.maxPayments }));
      setMessageModalCloseButtonLiteral(t("CloseButton"));
      setMessageModalOpen(true);
      return false;
    }
    // VALIDAR Amount de todos los payments es igual o menor que el amount de el pago
    const currentsubpaymentstotal = payerPaymentsUpdated
      .filter((element) => element.deleted == false)
      .reduce(function (a, b) {
        return (a += Number(b.amount));
      }, 0);
    const currentpaymenttotal = props.contentModel.paymentsList.find(
      (el) => el.paymentId === props.currentPaymentEditElement.paymentId
    ).amount;
    if (currentsubpaymentstotal > Number(currentpaymenttotal)) {
      if (props.currentPaymentEditElement.created == true) {
        props.currentPaymentEditElement.newPaymentIntent = true;
      }
      setMessageModalLiteral(t("TotalAmountMessage"));
      setMessageModalCloseButtonLiteral(t("CloseButton"));
      setMessageModalOpen(true);
      return false;
    }

    // Quitar porque ahora dicen que esto esta mal y se debe hacer un update
    /**
    const currentDataPayerPayment = props.contentModel.paymentsList.find(
      (el) => el.paymentId === props.currentPaymentEditElement.paymentId
    )?.payerPayments?.find(
      (el) => el.id === props.currentPaymentEditElement.id
      );

    if (currentDataPayerPayment && currentDataPayerPayment.payerId != props.currentPaymentEditElement.payerId) {
      let newPayment = Object.assign({}, props.currentPaymentEditElement);
      newPayment.created = true;
      const nextPayerId =
      payerPaymentsUpdated.length + 1;
      newPayment.id = '' + nextPayerId;
      payerPaymentsUpdated.push(newPayment);

      payerPaymentsUpdated.find(
        (el) => el.id === props.currentPaymentEditElement.id
      ).deleted = true;
    } */

    // if (currentPaymentSelectedPayer.newPayerIntent) {
    //   if (
    //     props.contentModel.payers.find(
    //       (el) =>
    //         el.deleted == false &&
    //         el.payerId === currentPaymentSelectedPayer.payerId
    //     )
    //   ) {
    //     setMessageModalLiteral(t("PayerInListMessage"));
    //     setMessageModalCloseButtonLiteral(t("CloseButton"));
    //     setMessageModalOpen(true);
    //     return false;
    //   }
    //   currentPaymentSelectedPayer.created = true;
    //   currentPaymentSelectedPayer.newPayerIntent = undefined;
    //   props.contentModel.payers.push(currentPaymentSelectedPayer);
    // } else {
    //   currentPaymentSelectedPayer.modified = true;
    //   props.contentModel.payers = props.contentModel.payers.map((element) => {
    //     if (element.payerId === currentPaymentSelectedPayer.payerId) {
    //       return { ...currentPaymentSelectedPayer };
    //     } else {
    //       // No changes
    //       return element;
    //     }
    //   });
    // }
    props.contentModel.paymentsList = props.contentModel.paymentsList.map(
      (element) => {
        if (element.paymentId === props.currentPaymentEditElement.paymentId) {
          element.payerPayments = payerPaymentsUpdated;
          return element;
        } else {
          // No changes
          return element;
        }
      }
    );
    props.setContentModel(props.contentModel);
    props.setPaymentGridOpen(false);
  };
  const modalPaymentGridBody = (
    <Box
      bgcolor="white"
      borderRadius={"32px"}
      p={4}
      paddingLeft={{ xs: "12px", md: "24px" }}
      paddingRight={{ xs: "12px", md: "24px" }}
      width={{ xs: "auto", md: "auto" }}
      style={{ maxHeight: "80%", overflow: "auto" }}
    >
      <Grid justify="center">
        <Grid textAlign={"right"}>
          <Button
            className="regular-b200 cl-secondary-900 IESE_Close_Button"
            variant="text"
            style={{ minWidth: 0 }}
            onClick={() => props.setPaymentGridOpen(false)}
          >
            <CloseIcon />
          </Button>
        </Grid>
        <div className="paymentEditPopUpPropertiesWrapper">
          <div>
            <div className="marginBottom16">
              <label>{t("PaymentsTable_Payment_Literal")} </label>
            </div>
            <Select
              id="paymentId"
              name="paymentId"
              displayEmpty
              style={{ width: "100%" }}
              readOnly={
                props.currentPaymentEditElement.newPaymentIntent ? null : true
              }
              value={props.currentPaymentEditElement.paymentId}
              onChange={handleInputChange}
            >
              <MenuItem disabled value="">
                <em>Placeholder</em>
              </MenuItem>
              {props.contentModel.paymentsList
                ? props.contentModel.paymentsList.map(
                    (paymentitem) =>
                      paymentitem.editable && (
                        <MenuItem
                          key={paymentitem.paymentId}
                          value={paymentitem.paymentId}
                        >
                          {paymentitem.concept}
                        </MenuItem>
                      )
                  )
                : null}
            </Select>
          </div>
          <div></div>
          <div>
            <div className="marginBottom16">
              <label>{t("PaymentsTable_Payer_Literal")} </label>
            </div>
            <Select
              id="payerId"
              name="payerId"
              displayEmpty
              style={{ width: "100%" }}
              // readOnly={
              //   props.currentPaymentEditElement.newPaymentIntent ? null : true
                          // }
              readOnly={!props.currentPaymentEditElement.payerEditable}
              value={props.currentPaymentEditElement.payerId}
              onChange={handleInputChange}
            >
            <MenuItem disabled value="">
              <em>Placeholder</em>
            </MenuItem>
              {props.contentModel.payers
                ? props.contentModel.payers.map(
                    (payeritem) =>
                      payeritem.deleted == false && (
                        <MenuItem
                          key={payeritem.payerId}
                          value={payeritem.payerId}
                        >
                          {payeritem.payerFullname}
                        </MenuItem>
                      )
                  )
                : null}
            </Select>
          </div>
          {/* <div>
            <div className="marginBottom16">
              <label>{t("PaymentsTable_PayerType_Literal")} </label>
            </div>
            <Select
              id="payerType"
              name="payerType"
              displayEmpty
              style={{ width: "100%" }}
              value={currentPaymentSelectedPayer.payerType}
              defaultValue={"Personal"}
              onChange={handleInputChangePayer}
            >
              <MenuItem value={"Personal"}>
                {t("PaymentsTable_PayerType_Personal_Literal")}
              </MenuItem>
              <MenuItem value={"Company"}>
                {t("PaymentsTable_PayerType_Company_Literal")}
              </MenuItem>
              <MenuItem value={"Other Personal"}>
                {t("PaymentsTable_PayerType_OtherPersonal_Literal")}
              </MenuItem>
              <MenuItem value={"Other Company"}>
                {t("PaymentsTable_PayerType_OtherCompany_Literal")}
              </MenuItem>
            </Select>
          </div>
          <div>
            <div className="marginBottom16">
              <label>{t("PaymentsTable_Payer_Literal")} </label>
            </div>
            <Select
              id="payerId"
              name="payerId"
              displayEmpty
              style={{ width: "100%" }}
              readOnly={
                props.currentPaymentEditElement.newPaymentIntent ? null : true
              }
              value={props.currentPaymentEditElement.payerId}
              onChange={handleInputChange}
            >
              <MenuItem value="">
                <em>{t("PaymentsTable_Payer_New_Literal")}</em>
              </MenuItem>
              {props.contentModel.payers
                ? props.contentModel.payers.map(
                    (payeritem) =>
                      payeritem.deleted == false && (
                        <MenuItem
                          key={payeritem.payerId}
                          value={payeritem.payerId}
                        >
                          {payeritem.payerFullname}
                        </MenuItem>
                      )
                  )
                : null}
            </Select>
          </div>
          <div>
            <div className="marginBottom16">
              <label>{t("PaymentsTable_PayerFiscalType_Literal")} </label>
            </div>
            <Select
              id="payerFiscalType"
              name="payerFiscalType"
              displayEmpty
              style={{ width: "100%" }}
              value={currentPaymentSelectedPayer.payerFiscalType}
              defaultValue={"F001"}
              onChange={handleInputChangePayer}
            >
              <MenuItem value={"F001"}>NIF</MenuItem>
              <MenuItem value={"F005"}>CIF</MenuItem>
              <MenuItem value={"F002"}>NIE</MenuItem>
              <MenuItem value={"F003"}>
                {t("PaymentsTable_PayerFiscalType_Passport_Literal")}
              </MenuItem>
            </Select>
          </div>
          <div>
            <div className="marginBottom16">
              <label>{t("PayerTable_PayerID_Literal")} </label>
            </div>
            <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="payerId"
              readOnly={
                props.currentPaymentEditElement.newPaymentIntent &&
                currentPaymentSelectedPayer.newPayerIntent == true
                  ? null
                  : true
              }
              value={currentPaymentSelectedPayer.payerId}
              onChange={handleInputChangePayer}
            />
          </div>
          <div className="gridColSpan2End">
            <div className="marginBottom16">
              <label>{t("PaymentsTable_Fullname_Literal")} </label>
            </div>
            <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="payerFullname"
              value={currentPaymentSelectedPayer.payerFullname}
              onChange={handleInputChangePayer}
            />
          </div>
          <div className="gridColSpan2End">
            <div className="marginBottom16">
              <label>{t("PayerTable_PayerAddress_Literal")} </label>
            </div>
            <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="payerFacturationAddress"
              value={currentPaymentSelectedPayer.payerFacturationAddress}
              onChange={handleInputChangePayer}
            />
          </div>
          <div>
            <div className="marginBottom16">
              <label>{t("PayerTable_PayerAddressCity_Literal")} </label>
            </div>
            <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="payerFacturationCity"
              value={currentPaymentSelectedPayer.payerFacturationCity}
              onChange={handleInputChangePayer}
            />
          </div>
          <div>
            <div className="marginBottom16">
              <label>{t("PayerTable_PayerAddressProvince_Literal")} </label>
            </div>
            <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="payerFacturationProvince"
              value={currentPaymentSelectedPayer.payerFacturationProvince}
              onChange={handleInputChangePayer}
            />
          </div>
          <div>
            <div className="marginBottom16">
              <label>{t("PayerTable_PayerAddressCP_Literal")} </label>
            </div>
            <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="payerFacturationCP"
              value={currentPaymentSelectedPayer.payerFacturationCP}
              onChange={handleInputChangePayer}
            />
          </div>
          <div>
            <div className="marginBottom16">
              <label>{t("PayerTable_PayerAddressCountry_Literal")} </label>
            </div>
            <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="payerFacturationCountry"
              value={currentPaymentSelectedPayer.payerFacturationCountry}
              onChange={handleInputChangePayer}
            />
          </div>
          <div>
            <div className="marginBottom16">
              <label>{t("PayerTable_PayerAddressMail_Literal")} </label>
            </div>
            <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="payerFacturationMail"
              value={currentPaymentSelectedPayer.payerFacturationMail}
              onChange={handleInputChangePayer}
            />
          </div> */}
          <div></div>

          <div>
            <div className="marginBottom16">
              <label>{t("PaymentsTable_DeadlinePayment_Literal")} </label>
            </div>
            {/* TODO: PONER MIN Y MAXDATE SEGUN LA QUE VENGA DE EL PAGO */}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
              <DatePicker
                name="deadline"
                value={dayjs(
                  props.currentPaymentEditElement.deadline,
                  "DD/MM/YYYY"
                )}
                readOnly={!props.currentPaymentEditElement.deadlineEditable}
                error={props.currentPaymentEditElement.deadline == null}
                // maxDate={props.contentModel.paymentsList
                //   ? props.contentModel.paymentsList.map(
                //       (paymentitem) =>
                //         paymentitem.editable && (
                //           <MenuItem
                //             key={paymentitem.paymentId}
                //             value={paymentitem.paymentId}
                //           >
                //             {paymentitem.concept}
                //           </MenuItem>
                //         )
                //     )
                //   : null}
                onChange={(newValue) =>
                  handleDatePickerInputChange("deadline", newValue)
                }
              />
            </LocalizationProvider>
          </div>
          <div></div>
          <div>
            <div className="marginBottom16">
              <label>{t("PaymentsTable_Amount_Literal")} </label>
            </div>
            <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="amount"
              type={"number"}
              readOnly={!props.currentPaymentEditElement.amountEditable}
              min={0}
              error={props.currentPaymentEditElement.amount == null || props.currentPaymentEditElement.amount === ''}
              defaultValue={props.currentPaymentEditElement.amount}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <Grid textAlign={"right"} marginTop={"20px"}>
          <Button
            className="regular-b200 cl-secondary-900 IESE_Confirm_Button"
            variant="text"
            onClick={() => saveCurrentPaymentGridElement()}
          >
            {t("SaveButton")}
          </Button>
        </Grid>
        <MessageModal
          show={messageModalOpen}
          close={() => setMessageModalOpen(false)}
          messageModalLiteral={messageModalLiteral}
          messageModalCloseButtonLiteral={messageModalCloseButtonLiteral}
        />
      </Grid>
    </Box>
  );
  const paymentGridModal = (
    <Modal
      open={props.paymentGridOpen}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={() => props.setPaymentGridOpen(false)}
    >
      {modalPaymentGridBody}
    </Modal>
  );

  return paymentGridModal;
}
PaymentModal.propTypes = {
  contentModel: PropTypes.object.isRequired,
  setContentModel: PropTypes.func.isRequired,
  paymentGridOpen: PropTypes.bool.isRequired,
  setPaymentGridOpen: PropTypes.func.isRequired,
  // setCurrentPayerEditElement: PropTypes.func.isRequired,
  currentPaymentEditElement: PropTypes.any,
  setCurrentPaymentEditElement: PropTypes.func.isRequired,
};
