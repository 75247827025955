import React from "react";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Modal from '@mui/material/Modal';

import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import MessageModal from "./MessageModal";
import ValidateSpanishID from "../../helpers/ValidateSpanishID";
import { MenuItem, Select } from "@mui/material";
import countriesdata from "../../assets/data/countriesdata.json";

export default function PayerModal(props) {
  const { t } = useTranslation();
  const [messageModalOpen, setMessageModalOpen] = React.useState(false);
  const [messageModalLiteral, setMessageModalLiteral] = React.useState("");
  const [messageModalCloseButtonLiteral, setMessageModalCloseButtonLiteral] = React.useState("");
  const handlePayerInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    props.setCurrentPayerEditElement({
      ...props.currentPayerEditElement,
      [name]: value,
    });
  };
  const saveCurrentPayerGridElement = () => {
    if (
      !props.currentPayerEditElement.payerId ||
      !props.currentPayerEditElement.payerFullname ||
      !props.currentPayerEditElement.payerFiscalType ||
      !props.currentPayerEditElement.payerFacturationCountry ||
      !props.currentPayerEditElement.payerFacturationMail
      // ||
      // !props.currentPayerEditElement.payerFacturationAddress ||
      // !props.currentPayerEditElement.payerFacturationCity ||
      // !props.currentPayerEditElement.payerFacturationProvince ||
      // !props.currentPayerEditElement.payerFacturationCP ||
      // !props.currentPayerEditElement.payerFacturationMail
    ) {
      setMessageModalLiteral(t("RequiredFieldsMessage"));
      setMessageModalCloseButtonLiteral(t("CloseButton"));
      setMessageModalOpen(true);
      return false;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(props.currentPayerEditElement.payerFacturationMail.replace(/\s/g, ''))) {
      setMessageModalLiteral(t("InvalidEmailMessage"));
      setMessageModalCloseButtonLiteral(t("CloseButton"));
      setMessageModalOpen(true);
      return false;
    }
    if (!ValidateSpanishID(props.currentPayerEditElement.payerFiscalType, props.currentPayerEditElement.payerId).valid) {
      setMessageModalLiteral(t("InvalidFiscalIDMessage"));
      setMessageModalCloseButtonLiteral(t("CloseButton"));
      setMessageModalOpen(true);
      return false;
    }
    if (props.currentPayerEditElement.newPayerIntent) {
      if (props.contentModel.payers.find(el => el.deleted == false && el.payerId === props.currentPayerEditElement.payerId)) {
        setMessageModalLiteral(t("PayerInListMessage"));
        setMessageModalCloseButtonLiteral(t("CloseButton"));
        setMessageModalOpen(true);
        return false;
      }
      props.currentPayerEditElement.created = true;
      props.currentPayerEditElement.newPayerIntent = undefined;
      props.contentModel.payers.push(props.currentPayerEditElement);
    }
    else {
      props.currentPayerEditElement.modified = true;
      if (props.currentPayerEditElement.previousPayerFiscalID != props.currentPayerEditElement.payerId) {
        if (props.contentModel.payers.find(el => el.deleted == false && el.payerId === props.currentPayerEditElement.payerId)) {
          setMessageModalLiteral(t("PayerInListMessage"));
          setMessageModalCloseButtonLiteral(t("CloseButton"));
          setMessageModalOpen(true);
          return false;
        }
        props.contentModel.payers = props.contentModel.payers.map((element) => {
          if (element.payerId === props.currentPayerEditElement.previousPayerFiscalID) {
            element.payerId = props.currentPayerEditElement.payerId;
            return element;
          } else {
            // No changes
            return element;
          }
        });
        props.contentModel.paymentsList = props.contentModel.paymentsList.map(
          (element) => {
            element.payerPayments = element.payerPayments.map((subelement) => {
              if (subelement.payerId === props.currentPayerEditElement.previousPayerFiscalID) {
                subelement.payerId = props.currentPayerEditElement.payerId;
                subelement.modified = true;
                return subelement;
              } else {
                // No changes
                return subelement;
              }
            })
            return element;
          }
        );
      }
      props.contentModel.payers = props.contentModel.payers.map((element) => {
        if (element.payerId === props.currentPayerEditElement.payerId) {
          return { ...props.currentPayerEditElement };
        } else {
          // No changes
          return element;
        }
      });
    }
    props.setContentModel(props.contentModel);
    props.setPayerGridOpen(false);
  }

  countriesdata.countries.sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));
    let countriesItems = [];
    {
        (function (rows, i, len) {
            while (i <= len) {
               rows.push(<MenuItem key={i} value={countriesdata.countries[i].value}>{countriesdata.countries[i].text}</MenuItem>)
                i++
            }
            return rows;
        })(countriesItems, 0, countriesdata.countries.length - 1)
    }

  const modalPayerGridBody = (
    <Box
      bgcolor="white"
      borderRadius={"32px"}
      p={4}
      paddingLeft={{ xs: "12px", md: "24px" }}
      paddingRight={{ xs: "12px", md: "24px" }}
      width={{ xs: "auto", md: "auto" }}
      style={{ maxHeight: "80%", overflow: "auto" }}
    >
      <Grid justify="center">
        <Grid textAlign={"right"}>
          <Button
            className="regular-b200 cl-secondary-900 IESE_Close_Button"
            variant="text"
            style={{ minWidth: 0 }}
            onClick={() => props.setPayerGridOpen(false)}
          >
            <CloseIcon />
          </Button>
        </Grid>
        <div className="paymentEditPopUpPropertiesWrapper">
        <div>
            <div className="marginBottom16">
              <label>{t("PaymentsTable_PayerType_Literal")} </label>
            </div>
            <Select
              id="payerType"
              name="payerType"
              displayEmpty
              style={{ width: "100%" }}
              value={props.currentPayerEditElement.payerType}
              defaultValue={"Personal"}
              onChange={handlePayerInputChange}
            >
              <MenuItem value={"Personal"}>
                {t("PaymentsTable_PayerType_Personal_Literal")}
              </MenuItem>
              <MenuItem value={"Company"}>
                {t("PaymentsTable_PayerType_Company_Literal")}
              </MenuItem>
              {/*<MenuItem value={"Other Personal"}>*/}
              {/*  {t("PaymentsTable_PayerType_OtherPersonal_Literal")}*/}
              {/*</MenuItem>*/}
              {/*<MenuItem value={"Other Company"}>*/}
              {/*  {t("PaymentsTable_PayerType_OtherCompany_Literal")}*/}
              {/*</MenuItem>*/}
            </Select>
          </div>
          <div>
            <div className="marginBottom16">
              <label>{t("PayerTable_PayerAddressCountry_Literal")} </label>
            </div>
            {/* <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="payerFacturationCountry"
              value={props.currentPayerEditElement.payerFacturationCountry}
              onChange={handlePayerInputChange}
            /> */}
            <Select
              id="payerFacturationCountry"
              name="payerFacturationCountry"
              displayEmpty
              style={{ width: "100%" }}
              value={props.currentPayerEditElement.payerFacturationCountry}
              error={props.currentPayerEditElement.payerFacturationCountry == null || props.currentPayerEditElement.payerFacturationCountry === ''}
              // readOnly={
              //   props.currentPayerEditElement.newPayerIntent == true
              //     ? null
              //     : true
              // }
              onChange={handlePayerInputChange}
            >
              {countriesItems}
            </Select>
          </div>
          <div>
            <div className="marginBottom16">
              <label>{t("PaymentsTable_PayerFiscalType_Literal")} </label>
            </div>
            <Select
              id="payerFiscalType"
              name="payerFiscalType"
              displayEmpty
              style={{ width: "100%" }}
              value={props.currentPayerEditElement.payerFiscalType}
              error={props.currentPayerEditElement.payerFiscalType == null || props.currentPayerEditElement.payerFiscalType === ''}
              defaultValue={"F004"}
              // readOnly={
              //   props.currentPayerEditElement.newPayerIntent == true
              //     ? null
              //     : true
              // }
              onChange={handlePayerInputChange}
            >
              {props.currentPayerEditElement.payerFacturationCountry === 'ES' && <MenuItem value={"F001"}>NIF</MenuItem>}
              {props.currentPayerEditElement.payerFacturationCountry === 'ES' && <MenuItem value={"F005"}>CIF</MenuItem>}
              {props.currentPayerEditElement.payerFacturationCountry === 'ES' && <MenuItem value={"F002"}>NIE</MenuItem>}
              <MenuItem value={"F004"}>Foreign Fiscal Id</MenuItem>
              {props.currentPayerEditElement.payerFacturationCountry !== 'ES' && <MenuItem value={"F003"}>
                {t("PaymentsTable_PayerFiscalType_Passport_Literal")}
              </MenuItem>}
            </Select>
          </div>
          <div>
            <div className="marginBottom16">
              <label>{t("PayerTable_PayerID_Literal")} </label>
            </div>
            <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="payerId"
              // readOnly={
              //   props.currentPayerEditElement.newPayerIntent == true
              //     ? null
              //     : true
              // }
              value={props.currentPayerEditElement.payerId}
              error={props.currentPayerEditElement.payerId == null || props.currentPayerEditElement.payerId === ''}
              onChange={handlePayerInputChange}
            />
          </div>
          <div className="gridColSpan2End">
            <div className="marginBottom16">
              <label>{t("PaymentsTable_Fullname_Literal")} </label>
            </div>
            <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="payerFullname"
              value={props.currentPayerEditElement.payerFullname}
              error={props.currentPayerEditElement.payerFullname == null || props.currentPayerEditElement.payerFullname === ''}
              onChange={handlePayerInputChange}
            />
          </div>
          <div className="gridColSpan2End">
            <div className="marginBottom16">
              <label>{t("PayerTable_PayerAddress_Literal")} </label>
            </div>
            <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="payerFacturationAddress"
              value={props.currentPayerEditElement.payerFacturationAddress}
              onChange={handlePayerInputChange}
            />
          </div>
          <div>
            <div className="marginBottom16">
              <label>{t("PayerTable_PayerAddressCity_Literal")} </label>
            </div>
            <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="payerFacturationCity"
              value={props.currentPayerEditElement.payerFacturationCity}
              onChange={handlePayerInputChange}
            />
          </div>
          <div>
            <div className="marginBottom16">
              <label>{t("PayerTable_PayerAddressProvince_Literal")} </label>
            </div>
            <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="payerFacturationProvince"
              value={props.currentPayerEditElement.payerFacturationProvince}
              onChange={handlePayerInputChange}
            />
          </div>
          <div>
            <div className="marginBottom16">
              <label>{t("PayerTable_PayerAddressCP_Literal")} </label>
            </div>
            <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="payerFacturationCP"
              value={props.currentPayerEditElement.payerFacturationCP}
              onChange={handlePayerInputChange}
            />
          </div>
          <div>
            <div className="marginBottom16">
              <label>{t("PayerTable_PayerAddressMail_Literal")} </label>
            </div>
            <Input
              style={{ width: "100%" }}
              className="inputTextClassic"
              name="payerFacturationMail"
              error={props.currentPayerEditElement.payerFacturationMail == null || 
                props.currentPayerEditElement.payerFacturationMail === '' ||
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(props.currentPayerEditElement.payerFacturationMail.replace(/\s/g, ''))
              }
              value={props.currentPayerEditElement.payerFacturationMail}
              onChange={handlePayerInputChange}
            />
          </div>
          {/* <div>
            <label>{t("PayerTable_PayerID_Literal")}: </label>
          </div>
          <div>
            <Input
              style={{ width: "100%" }}
              name="payerId"
              defaultValue={props.currentPayerEditElement.payerId}
              onChange={handlePayerInputChange}
            />
          </div>
          <div>
            <label>{t("PayerTable_PayerAddress_Literal")}: </label>
          </div>
          <div>
            <Input
              style={{ width: "100%" }}
              name="facturationAddress"
              defaultValue={props.currentPayerEditElement.facturationAddress}
              onChange={handlePayerInputChange}
            />
          </div>
          <div>
            <label>{t("PayerTable_PayerName_Literal")}: </label>
          </div>
          <div>
            <Input
              style={{ width: "100%" }}
              name="payer"
              defaultValue={props.currentPayerEditElement.payer}
              onChange={handlePayerInputChange}
            />
          </div> */}
        </div>
        <Grid textAlign={"right"} marginTop={"20px"}>
          <Button
            className="regular-b200 cl-secondary-900 IESE_Confirm_Button"
            variant="text"
            onClick={() => saveCurrentPayerGridElement()}
          >
            {t("SaveButton")}
          </Button>
        </Grid>
      <MessageModal
        show={messageModalOpen}
        close={() => setMessageModalOpen(false)}
        messageModalLiteral={messageModalLiteral}
        messageModalCloseButtonLiteral={messageModalCloseButtonLiteral}
      />
      </Grid>
    </Box>
  );
  const payerGridModal = (
    <Modal
      open={props.payerGridOpen}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={() => props.setPayerGridOpen(false)}
    >
      {modalPayerGridBody}
    </Modal>
  );
  
  return (
    payerGridModal
  );
}
PayerModal.propTypes = {
  contentModel: PropTypes.object.isRequired,
  setContentModel: PropTypes.func.isRequired,
  payerGridOpen: PropTypes.bool.isRequired,
  setPayerGridOpen: PropTypes.func.isRequired,
  currentPayerEditElement: PropTypes.any,
  setCurrentPayerEditElement: PropTypes.func.isRequired,
};
