import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Modal } from "@mui/material";

export default function MessageModal(props) {
  const savedmodalBody = (
    <Box
      bgcolor="white"
      borderRadius={"32px"}
      p={4}
      style={{ maxHeight: "80%", overflow: "auto" }}
    >
      <div
        className="paddingBottom32"
        style={{
          paddingTop: "16px",
          paddingLeft: "32px",
          paddingRight: "32px",
        }}
      >
        <span className="regular-b400">{props.messageModalLiteral}</span>
      </div>
      <div
        className="MessageModalActionsWrapper"
        style={{ textAlign: "center" }}
      >
        {props.confirm ? (
          <Button
            className="regular-b300 cl-secondary-100 IESE_Confirm_Button"
            variant="text"
            onClick={props.confirmfunc}
          >
            {props.messageModalConfirmButtonLiteral}
          </Button>
        ) : null}
        <Button
          className="regular-b300 cl-secondary-100 IESE_Confirm_Button"
          variant="text"
          onClick={props.close}
        >
          {props.messageModalCloseButtonLiteral}
        </Button>
      </div>
    </Box>
  );
  return (
    <Modal
      open={props.show}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={props.close}
    >
      {savedmodalBody}
    </Modal>
  );
}
MessageModal.propTypes = {
  show: PropTypes.bool.isRequired,
  confirm: PropTypes.bool,
  close: PropTypes.func.isRequired,
  confirmfunc: PropTypes.any,
  messageModalLiteral: PropTypes.string.isRequired,
  messageModalCloseButtonLiteral: PropTypes.string.isRequired,
  messageModalConfirmButtonLiteral: PropTypes.string,
};
