import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import InfoIcon from "@mui/icons-material/Info";

import { useTranslation } from "react-i18next";
import PaymentModal from "../PaymentModal";
import MessageModal from "../MessageModal";

export default function PaymentPlanGrid(props) {
  const { t } = useTranslation();
  const [paymentGridOpen, setPaymentGridOpen] = useState(false);
  const [currentPaymentEditElement, setCurrentPaymentEditElement] = useState(0);
  const [messageModalOpen, setMessageModalOpen] = React.useState(false);
  const [messageModalLiteral, setMessageModalLiteral] = React.useState("");
  const [messageModalCloseButtonLiteral, setMessageModalCloseButtonLiteral] =
    React.useState("");
  const [
    messageModalConfirmButtonLiteral,
    setMessageModalConfirmButtonLiteral,
  ] = React.useState("");
  const [messageModalConfirm, setMessageModalConfirm] = React.useState(false);
  const [currentPaymentDeleteElement, setCurrentPaymentDeleteElement] =
    useState(0);

  const openCurrentPaymentGridElement = (element) => {
    setCurrentPaymentEditElement(element);
    setPaymentGridOpen(true);
  };

  const deleteCurrentPaymentGridElement = (deleteelement) => {
    setCurrentPaymentDeleteElement(deleteelement);
    setMessageModalConfirm(true);
    setMessageModalLiteral(t("ConfirmPaymentDeleteMessage"));
    setMessageModalCloseButtonLiteral(t("CancelButton"));
    setMessageModalConfirmButtonLiteral(t("DeleteButton"));
    setMessageModalOpen(true);
  };
  const confirmDeleteCurrentPaymentGridElement = () => {
    // let payerPaymentsUpdated = props.contentModel.paymentsList
    //   .find(
    //     (element) => element.paymentId == currentPaymentDeleteElement.paymentId
    //   )
    //   .payerPayments.filter(
    //     (element) => element.id !== currentPaymentDeleteElement.id
    //   );

    // props.contentModel.paymentsList = props.contentModel.paymentsList.map(
    //   (element) => {
    //     if (element.paymentId === currentPaymentDeleteElement.paymentId) {
    //       element.payerPayments = payerPaymentsUpdated;
    //       return element;
    //     } else {
    //       // No changes
    //       return element;
    //     }
    //   }
    // );
    currentPaymentDeleteElement.deleted = true;
    let payerPaymentsUpdated = props.contentModel.paymentsList
      .find(
        (element) => element.paymentId == currentPaymentDeleteElement.paymentId
      )
      .payerPayments.map((element) => {
        if (element.id === currentPaymentDeleteElement.id) {
          return { ...currentPaymentDeleteElement };
        } else {
          // No changes
          return element;
        }
      });
    props.contentModel.paymentsList = props.contentModel.paymentsList.map(
      (element) => {
        if (element.paymentId === currentPaymentDeleteElement.paymentId) {
          element.payerPayments = payerPaymentsUpdated;
          return element;
        } else {
          // No changes
          return element;
        }
      }
    );

    props.setContentModel({ ...props.contentModel });
    closeMessageConfirmModal();
  };
  const closeMessageConfirmModal = () => {
    setCurrentPaymentDeleteElement(0);
    setMessageModalConfirm(false);
    setMessageModalOpen(false);
  };
  const openNewPaymentGridElement = () => {
    setCurrentPaymentEditElement({
      paymentId: "",
      payerId: "",
      deleted: false,
      amount: props.contentModel.minPaymentAmount,
      newPaymentIntent: true,
      editable: true,
      canDelete: true,
        payerEditable: true,
        deadlineEditable: true,
        amountEditable: true
    });
    setPaymentGridOpen(true);
  };
  // Create our number formatter.
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: props.contentModel.currency,

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  const formatDate = (date) => {
    if (date !== undefined && date !== "") {
      var myDate = new Date(date);
      var month = [
        t("Month_Jan"),
        t("Month_Feb"),
        t("Month_Mar"),
        t("Month_Apr"),
        t("Month_May"),
        t("Month_Jun"),
        t("Month_Jul"),
        t("Month_Aug"),
        t("Month_Sep"),
        t("Month_Oct"),
        t("Month_Nov"),
        t("Month_Dec"),
      ][myDate.getMonth()];
      var str = myDate.getDate() + "-" + month + "-" + myDate.getFullYear();
      return str;
    }
    return "";
  };
  const dateLiteralFormater = (dateliteral) => {
    let [day, month, year] = dateliteral.split("/");
    const dateObj = new Date(+year, +month - 1, +day);
    return formatDate(dateObj);
  };
  let count = 1;
  return (
    <>
      <h3 className="semibold-h300">
        {t("PaymentsTable_PaymentPlan_Literal")}
        <div style={{ paddingTop: "12px" }}>
          <Button
            style={{ padding: "0" }}
            className="IESE_Close_Button cl-primary-500 IESE_Confirm_Button"
            variant="text"
            onClick={() => {
              openNewPaymentGridElement();
            }}
          >
            {t("AddButton")}
          </Button>
        </div>
      </h3>
      {/* <div className="ValidationsHelperWrapper">
        <div className="regular-b300">
            <InfoIcon className="InfoIcon" />{t("ValidationHelper_TitleMessage")}</div>
        <ul>
          <li className="regular-b300">{t("ValidationHelper_MaxPaymentsMessage", { num: props.contentModel.maxPayments })}</li>
          <li className="regular-b300">{t("ValidationHelper_MinPaymentAmountMessage", { num: formatter.format(props.contentModel.minPaymentAmount) })}</li>
        </ul>
      </div> */}
      <div style={{ overflow: "auto" }}>
        <Table className="iese-payments-table">
          <TableHead>
            <TableRow style={{ backgroundColor: "red" }} key={count++}>
              <TableCell></TableCell>
              <TableCell style={{ color: "white" }}>
                {t("PaymentsTable_Concept_Literal")}
              </TableCell>
              <TableCell style={{ color: "white" }}>
                {t("PaymentsTable_Payer_Literal")}
              </TableCell>
              <TableCell style={{ color: "white" }}>
                {t("PaymentsTable_DeadlinePayment_Literal")}
              </TableCell>
              <TableCell style={{ color: "white" }}>
                {t("PaymentsTable_DueDate_Literal")}
              </TableCell>
              <TableCell style={{ color: "white" }}>
                {t("PaymentsTable_Amount_Literal")}
              </TableCell>
              {/* <TableCell style={{ color: "white" }}>{t("PaymentsTable_AcademicYear_Literal")}</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.contentModel.paymentsList.map((item) =>
              item.payerPayments
                .filter((subitem) => subitem.deleted == false)
                .map(
                  (subitem, index) =>
                    subitem.deleted == false && (
                      <>
                        {index === 0 && (
                          <TableRow key={count++}>
                            <TableCell colSpan={"6"} className="semibold-b200">
                              {item.concept}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow key={count++}>
                          <TableCell>
                            {subitem.editable && (
                              <Button
                                style={{ padding: "0" }}
                                className="IESE_Close_Button cl-primary-500"
                                variant="text"
                                onClick={() => {
                                  openCurrentPaymentGridElement(subitem);
                                }}
                              >
                                {t("EditButton")}
                              </Button>
                            )}
                            {subitem.canDelete && (
                              <Button
                                style={{ padding: "0" }}
                                className="IESE_Close_Button cl-primary-500"
                                variant="text"
                                onClick={() => {
                                  deleteCurrentPaymentGridElement(subitem);
                                }}
                              >
                                {t("DeleteButton")}
                              </Button>
                            )}
                          </TableCell>
                          <TableCell>{item.concept}</TableCell>
                          <TableCell>
                            {
                              props.contentModel.payers.find(
                                (element) => element.payerId == subitem.payerId
                              )?.payerFullname
                            }
                          </TableCell>
                          <TableCell>
                            {dateLiteralFormater(subitem.deadline)}
                          </TableCell>
                          <TableCell>
                            {dateLiteralFormater(item.duedate)}
                          </TableCell>
                          <TableCell>
                            {subitem.amount && formatter.format(subitem.amount)}
                          </TableCell>
                          {/* <TableCell>{item.academicYear}</TableCell> */}
                        </TableRow>
                        {index ===
                          item.payerPayments.filter(
                            (subitem) => subitem.deleted == false
                          ).length -
                            1 && (
                          <TableRow key={count++}>
                          <TableCell colSpan={"5"}>
                          </TableCell>
                            <TableCell className="semibold-b200">
                              {formatter.format(
                                item.payerPayments
                                  .filter((subitem) => subitem.deleted == false)
                                  .reduce(function (a, b) {
                                    return (a += Number(b.amount));
                                  }, 0)
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )
                )
            )}
            <TableRow style={{ backgroundColor: "red" }} key={count++}>
              <TableCell style={{ color: "white" }} colSpan={"5"} className="semibold-b200">
                {t("Total_Literal")}
              </TableCell>
              <TableCell style={{ color: "white" }} className="semibold-b200">
                {formatter.format(
                  props.contentModel.paymentsList
                    .map((item) =>
                      item.payerPayments
                        .filter((subitem) => subitem.deleted == false)
                        .reduce(function (a, b) {
                          return (a += Number(b.amount));
                        }, 0)
                    )
                    .reduce(function (a, b) {
                      return (a += Number(b));
                    }, 0)
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <PaymentModal
          contentModel={props.contentModel}
          setContentModel={props.setContentModel}
          paymentGridOpen={paymentGridOpen}
          setPaymentGridOpen={setPaymentGridOpen}
          currentPaymentEditElement={currentPaymentEditElement}
          setCurrentPaymentEditElement={setCurrentPaymentEditElement}
        />
        <MessageModal
          show={messageModalOpen}
          close={closeMessageConfirmModal}
          messageModalLiteral={messageModalLiteral}
          messageModalCloseButtonLiteral={messageModalCloseButtonLiteral}
          messageModalConfirmButtonLiteral={messageModalConfirmButtonLiteral}
          confirm={messageModalConfirm}
          confirmfunc={confirmDeleteCurrentPaymentGridElement}
        />
      </div>
    </>
  );
}
PaymentPlanGrid.propTypes = {
  contentModel: PropTypes.object.isRequired,
  setContentModel: PropTypes.func.isRequired,
};
