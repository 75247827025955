//https://gist.github.com/afgomez/5691823
'use strict';
    
var DNI_REGEX = /^(\d{8})([A-Z])$/;
var CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
const PASSPORT_REGEX = /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/;

export default function ValidateSpanishID( type, str ) {

  // Ensure upcase and remove whitespace
  str = str.toUpperCase().replace(/\s/, '');

  var valid = false;
  // var type = spainIdType( str );

  // switch (type) {
  //   case 'dni':
  //     valid = validDNI( str );
  //     break;
  //   case 'nie':
  //     valid = validNIE( str );
  //     break;
  //   case 'cif':
  //     valid = validCIF( str );
  //     break;
  //   case 'passport':
  //     valid = true;
  //     break;
  // }
  
  switch (type) {
    case 'F001':
      valid = spainIdType( str ) == 'dni' && validDNI( str );
      break;
    case 'F002':
      valid = spainIdType( str ) == 'nie' && validNIE( str );
      break;
    case 'F005':
      valid = spainIdType( str ) == 'cif' && validCIF( str );
      break;
    case 'F003':
      valid = true ;
      break;
    case 'F004':
      valid = true ;
      break;
  }

  return {
    type: type,
    valid: valid
  };

};

var spainIdType = function( str ) {
  if ( str.match( DNI_REGEX ) ) {
    return 'dni';
  }
  if ( str.match( CIF_REGEX ) ) {
    return 'cif';
  }
  if ( str.match( NIE_REGEX ) ) {
    return 'nie';
  }
   if (str.match(PASSPORT_REGEX ) ) {
    return 'passport';
  }
};

var validDNI = function( dni ) {
  var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
  var letter = dni_letters.charAt( parseInt( dni, 10 ) % 23 );
  
  return letter == dni.charAt(8);
};

var validNIE = function( nie ) {

  // Change the initial letter for the corresponding number and validate as DNI
  var nie_prefix = nie.charAt( 0 );

  switch (nie_prefix) {
    case 'X': nie_prefix = 0; break;
    case 'Y': nie_prefix = 1; break;
    case 'Z': nie_prefix = 2; break;
  }

  return validDNI( nie_prefix + nie.substr(1) );

};

var validCIF = function (cif) {
   if (!cif || cif.length !== 9) {
      return false;
   }

   var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
   var digits = cif.substr(1, cif.length - 2);
   var letter = cif.substr(0, 1);
   var control = cif.substr(cif.length - 1);
   var sum = 0;
   var i;
   var digit;

   if (!letter.match(/[A-Z]/)) {
      return false;
   }

   for (i = 0; i < digits.length; ++i) {
      digit = parseInt(digits[i]);

      if (isNaN(digit)) {
         return false;
      }

      if (i % 2 === 0) {
         digit *= 2;
         if (digit > 9) {
            digit = parseInt(digit / 10) + (digit % 10);
         }

         sum += digit;
      } else {
         sum += digit;
      }
   }

   sum %= 10;
   if (sum !== 0) {
      digit = 10 - sum;
   } else {
      digit = sum;
   }

   if (letter.match(/[ABEH]/)) {
      return String(digit) === control;
   }
   if (letter.match(/[NPQRSW]/)) {
      return letters[digit] === control;
   }

   return String(digit) === control || letters[digit] === control;
}


//var validCIF = function( cif ) {

//  var match = cif.match( CIF_REGEX );
//  var letter  = match[1],
//      number  = match[2],
//      control = match[3];

//  var even_sum = 0;
//  var odd_sum = 0;
//  var n;

//  for ( var i = 0; i < number.length; i++) {
//    n = parseInt( number[i], 10 );

//    // Odd positions (Even index equals to odd position. i=0 equals first position)
//    if ( i % 2 === 0 ) {
//      // Odd positions are multiplied first.
//      n *= 2;

//      // If the multiplication is bigger than 10 we need to adjust
//      odd_sum += n < 10 ? n : n - 9;

//    // Even positions
//    // Just sum them
//    } else {
//      even_sum += n;
//    }

//  }

//  var control_digit = (10 - (even_sum + odd_sum).toString().substr(-1) );
//  var control_letter = 'JABCDEFGHI'.substr( control_digit, 1 );

//  // Control must be a digit
//  if ( letter.match( /[ABEH]/ ) ) {
//    return control == control_digit;

//  // Control must be a letter
//  } else if ( letter.match( /[KPQS]/ ) ) {
//    return control == control_letter;

//  // Can be either
//  } else {
//    return control == control_digit || control == control_letter;
//  }

//};